//export const PATH_API = "http://localhost:3001";
export const PATH_API = process.env.PATH_API; 
export const STRIPE_TOKEN = String(process.env.STRIPE_TOKEN);
export const API_V1= "/api";


export const getPathImageCabinType=(id)=>{
    return PATH_API+API_V1+'/uploads/cabin-types/'+id;
}

export const getPathImageCabinTypeGallery=(id)=>{
    return PATH_API+API_V1+'/uploads/cabin-types-gallery/'+id;
} 

export const CABIN_TYPES = {
    REGULAR:1,
    SPECIAL:2
}