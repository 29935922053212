import axios from 'axios';
import {PATH_API} from '../contants/apis';
import {getItem,TOKEN_USER} from '../utils/storage';

const clientAxios=axios.create({
    baseURL:PATH_API
});

const isHandlerEnabled = (config={}) => {
    return !(config.hasOwnProperty('handlerEnabled') && !config.handlerEnabled)
};

//interceptor
const requestHandler = (request) => {
    if (isHandlerEnabled(request)) {
      request.headers['authorization'] = getItem(TOKEN_USER);
    }
    return request
};

clientAxios.interceptors.request.use(
    request => requestHandler(request)
); 
export default clientAxios;